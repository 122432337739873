.alert {
  border-radius: 0;
}

.alert-success {
  background-color: $success_color;
}

.alert-danger {
  background-color: $secondary_color_muted;
}

.alert-dismissible .close {
  height: 100%;
  top: -3px;
}

.close:hover {
  color: $white;
}

.alert-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
}

.rtl {
  .alert-popup {
    right: unset;
    left: 10px;
  }
}
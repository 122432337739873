// SVG icons
$svgIcons: search, youtube-play;

@each $svgIcon in $svgIcons {
  .#{$svgIcon}-icon {
    background-image: url('../images/icons/#{$svgIcon}.svg');
  }
}

// PNG icons
$pngIcons: equipment, parts, services, finance,
           facebook, youtube, whatsapp, instagram, linkedin, twitter,
           facebook-mono, youtube-mono, whatsapp-mono, instagram-mono, linkedin-mono, twitter-mono;

@each $pngIcon in $pngIcons {
  .#{$pngIcon}-icon {
    background-image: url('../images/icons/#{$pngIcon}.png');
  }
}

// Styles for Icons
.player-icon {
    background-size: 80px;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.4s ease all;

    &:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,0.5);
    }
}
.facebook-icon-color {
    color: #3d5b99;

    &:hover {
        color: darken(#3d5b99, 10);
    }
}

.whatsapp-icon-color {
    color: #4caf50;

    &:hover {
        color: darken(#4caf50, 10);
    }
}

// Slider-pro icons
.sp-grab{
    cursor:url(/images/openhand.cur),move
}

.sp-grabbing{
    cursor:url(/images/closedhand.cur),move
}

.product__purchase {
  padding: 20px;
  background: $white;

  p {
    margin-bottom: 0;
  }

  .product-price {
      font-size: 1rem;
  }
}

.product__purchase-top {
  margin-bottom: $card_bottom_gap;
}

.product-price {
  color: $secondary_color_muted;
  font-weight: 500;
  font-size: 0.9rem;

  small {
    color: $muted_color;
  }
}

.in-stock {
  font-size: 1.3rem;
  font-weight: 400;
  color: $muted_color;
}

.in-stock-icon {
    color: $success_color;
}

.in-stock-icon ~ .in-stock-status {
    padding: 0 6px;
}

.product__short-description {
  font-size: 14px;
  line-height: 18px;
  color: $muted_color;
}

.ltr {
  display: inline-block;
  direction: ltr !important;
}

.border-light-sm {
  border: $border_light !important;
}

.text-end {
  text-align: end !important;
}

.text-sm {
  font-size: .8rem !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-primary {
  color: $primary_color !important;
}

.text-danger {
  color: $secondary_color !important;
}

.text-muted {
  color: $muted_color !important;
}

.text-primary-light {
  color: $primary_color_light !important;
}

.bg-muted {
  background: $white_muted;
}

.mb-card {
  margin-bottom: $card_bottom_gap !important;
}

.hover-cp {
  &:hover {
    cursor: pointer !important;
  }
}

.pos-a-center {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  display: inline-block;
}

.label-top-offset {
  margin-top: 22px;
}

.va-t {
    vertical-align: top !important;
}

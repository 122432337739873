.sidebar-filter {
    margin-bottom: $card_bottom_gap;
    background: $white;
}

.sidebar-filter__item {
    border-bottom: 1px solid $border_color;
}

.sidebar-filter__item__title {
    margin-bottom: 0;
    padding: $default_gap $default_gap 0;
    font-size: 1rem;
    font-weight: 400;
    color: $muted_color;
}

.sidebar-filter__item__filters {
    padding: 15px;
}

.sidebar-filter__item__filter {
    padding: 4px 0 4px 24px;
    
    label:hover {
        cursor: pointer;
    }
}

.sidebar-filter__item__filter__collapsed {
    margin-top: 10px;

    .sidebar-filter__item__filter {
        padding-bottom: 0;
    }
}

.rtl .sidebar-filter__item__filter {
    margin-left: 0;
    padding-left: 0;
}






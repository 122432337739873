// Headings
h1, h2 {
    margin: 0;
    font-weight: 400;
    color: $primary_color;
}

h1 {
    font-size: 2.2rem;
    font-family: MoolBoran, Roboto;
    text-transform: uppercase;
}

h2 {
    line-height: 1.1;
    font-size: 1.5rem;
}

h5 {
    font-size: 1rem;
}

.page-title {
    margin-bottom: 15px;
    padding-bottom: 33px;
    position: relative;
    text-transform: none;
    font-family: Roboto;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 1rem;
        width: 3rem;
        height: 3px;
        background: $secondary_color;
    }
}

.rtl {
    .page-title:after {
        right: 0 !important;
    }
}

.title-404 {
    display: flex;
    height: 150px;
    justify-content: center;
    color: $secondary_color_light;
    font-weight: 100;
    font-style: italic;
    font-size: 150px;
}

a {
    text-decoration: none !important;
    outline: none !important;
    transition: $default_transition;
}

p {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 10px;
    color: $muted_color;
}

strong,
b {
    font-weight: 500;
}

@include for-tablet-portrait {
    h1, h2 {
        line-height: 1.2;
    }

    h1 {
        font-size: 2.6rem;
    }

    h2 {
        font-size: 2rem;
    }

    h5 {
        font-size: 1.25rem;
    }
}

label {
    color: $muted_color;
    font-weight: 400;
    font-size: .9rem;
}

input,
textarea,
button,
select {
    outline: none !important;

    &:focus,
    &:active {
        outline: none !important;
    }
}

.form-group {
    input,
    textarea,
    select {
        padding: 5px 12px;
        height: 32px;
        width: 100%;
        display: block;
        border: 1px solid $border_color;
        border-radius: 0;
        box-shadow: none !important;
        line-height: 1.42857143;
        font-size: 14px;
        background-color: $white;
        background-image: none;
        transition: $default_transition, background-position 0s;
        color: $primary_color;

        &:focus,
        &:active {
            border-color: $muted_color_light;
        }
    }

    textarea {
        height: auto;
        min-height: 138px;
        resize: vertical;
    }

    .was-validated .form-control {
        &:invalid,
        &.is-invalid {
            border-color: $secondary_color;
        }
    }
}

.custom-control-label {
    width: 90%;
    line-height: 16px;
    font-size: 13px;
    color: $muted_color;
}

.custom-checkbox .custom-control-label {
    &::after,
    &::before {
        top: 0;
        width: 16px;
        height: 16px;
        border-radius: 3px;
    }
}

.custom-checkbox .custom-control-input {
    &:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
        background-size: 9px;
    }

    &:checked ~ .custom-control-label::before {
        border-color: $secondary_color;
        background-color: $secondary_color;
        transition: .25s cubic-bezier(0.4, 0, 0.45, 1) all;
    }

    &:not(:disabled):active ~ .custom-control-label::before {
        background-color: $white;
        border-color: $muted_color_light;
    }

    &:focus ~ .custom-control-label::before {
        box-shadow: none;
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
        border-color: $muted_color_light;
        box-shadow: none;
    }
}

.invalid-feedback {
    color: $secondary_color;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%25dc3545' stroke='none'/%3E%3C/svg%3E");
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}

/* Bootstrap 4 text input with search icon */
.has-search {
    .form-control {
        padding-left: 32px;
    }

    .form-control-feedback {
        position: absolute;
        z-index: 2;
        display: block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        pointer-events: none;
        color: $muted_color_light;
        font-size: 11px;
    }
}

#map {
    height: 100%;
    min-height: 400px;
}

.contact-us__form-map {
    margin: 0 0 50px;
    box-shadow: $shadow_sm;
    background: $white;
}

.contact-us__form {
    background: $white;
}

.contact-us__form__recaptcha,
.contact-us__form__submit {
    text-align: center;
}

.g-recaptcha {
    display: inline-block;
    width: 260px;
    direction: ltr !important;
    transform: scale(0.86);
    transform-origin: 0 0;
}

@media (min-width: 360px) {
    .g-recaptcha {
        width: 300px;
        transform: scale(1);
    }
}

@include for-phone() {
    .contact-us__form__recaptcha,
    .contact-us__form__submit {
        text-align: unset;
    }

    .contact-us__form__submit-btn {
        width: 100%;
        height: 100%;
        max-height: 76px;
        font-size: 1.2rem;
    }
}

.custom-modal-close {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 1;

    &:hover {
        color: $secondary_color;
    }
}

.rtl .custom-modal-close {
    left: 5px;
    right: auto;
}
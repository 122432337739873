.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 20px;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'></path></svg>");
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'></path></svg>");
}

.carousel-caption {
    top: 10%;
    z-index: 1;
    text-align: inherit;
}

.home-slider {
    height: 400px;

    h1, p {
        color: $white;
    }

    .carousel-inner {
        height: 100%;
    }

    .carousel-item {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &.blackout:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .4);
        }
    }

    .carousel-title {
        margin-bottom: 15px;
    }

    .carousel-sub-title {
        margin-bottom: $default_gap;
        font-size: .9rem;
    }

    .carousel-read-more {
        margin: auto;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 20%;
        z-index: 2;
        width: 70%;
        text-align: center;
    }

    @include for-phone {
        .carousel-title {
            margin-bottom: 20px;
        }

        .carousel-sub-title {
            font-size: 1rem;
            margin-bottom: 20px;
        }
    }

    @include for-tablet-portrait {
        .carousel-sub-title {
            font-size: 1.2rem;
            margin-bottom: 50px;
        }
    }

    @include for-desktop {
        .home-slider {
            height: 500px;
        }
    }
}

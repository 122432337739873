.cart-success {
    position: fixed;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 30px;
    background: $success_color;
    color: $white;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    z-index: 10;
}

@include for-tablet-portrait {
    .cart-success {
        font-size: .7rem;
    }
}

@include for-desktop {
    .cart-success {
        font-size: .8rem;
    }
}

.header-top {
  width: 100%;
  border-bottom: 1px solid $border-color;

  a:hover {
    color: $primary_color_light;
  }
}

.header-contact,
.header__lang-switcher {
  font-size: 13px;
  color: $muted_color;
}

.header__social-icon {
  margin-right: 10px;
  height: $social_icon_size;
  width: $social_icon_size;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &:last-child {
    margin: 0;
  }
}

.rtl .header__social-icon {
  margin-right: 0;
  margin-left: 10px;

  &:last-child {
    margin-left: 0;
  }
}

// Language switcher
.language-switcher input{
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.flag-ar {
  background-image: url('/images/icons/flag-ar.svg');
}

.flag-en {
  background-image: url('/images/icons/flag-en.svg');
}

.language-switcher input:active + .language_label {
  opacity: .9;
}

.language-switcher input:checked + .language_label {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.language_label {
  margin: 3px 0 0;
  padding: 0;
  width: 25px;
  height: 16px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
  filter: brightness(1.8) grayscale(1) opacity(.7);
}
.language_label:hover {
  -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
  -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
  filter: brightness(1.2) grayscale(.5) opacity(.9);
}

@include for-tablet-portrait {
  .header-contact {
    font-size: 14px;
  }
}

.homepage {
    section {
        padding: 40px 0;
    }
}

.home-icons {
    padding: 0 0 30px !important;
    position: relative;
    z-index: 10;
    background: transparent;
}

.home-icons__item {
    margin-top: $card_bottom_gap;
    width: 180px;
    align-self: center;
    text-align: center;
}

.home-icons__link {
    display: block;
    font-size: 1.5rem;
    color: $muted_color;

    &:hover {
        color: $secondary_color_muted;

        .home-icons__icon {
            transform: scale(1.15);
        }
    }
}

.home-icons__icon {
    margin-bottom: 10px;
    width: 150px;
    height: 80px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: $default_transition;
}

.home-icons__label {
    display: block;
}

@include for-tablet-portrait {
    .home-icons__item {
        width: 200px;
    }

    .homepage-title {
        font-size: 3rem;
    }
}

@include for-tablet-landscape {
    .home-icons__link::before {
        content: "";
        position: relative;
        top: -10px;
        right: 20px;
        display: inline-block;
        width: 1px;
        height: 70px;
        background: $muted_color_light;
    }

    .rtl  .home-icons__link::before {
        right: -20px;
    }

    .home-icons__item:first-child .home-icons__link::before {
        display: none;
    }
}

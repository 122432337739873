@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');

@font-face {
    font-family: 'MoolBoran';
    src: url('/fonts/hinted-MoolBoran.eot');
    src: local('MoolBoran'),
    url('/fonts/MoolBoran/hinted-MoolBoran.eot?#iefix') format('embedded-opentype'),
    url('/fonts/MoolBoran/hinted-MoolBoran.woff2') format('woff2'),
    url('/fonts/MoolBoran/hinted-MoolBoran.woff') format('woff'),
    url('/fonts/MoolBoran/hinted-MoolBoran.ttf') format('truetype'),
    url('/fonts/MoolBoran/hinted-MoolBoran.svg#MoolBoran') format('svg');
    font-weight: normal;
    font-style: normal;
}


.equipment-card {
  padding: 15px;
  margin-bottom: $card_bottom_gap;
  background: $white;
  transition: $default_transition;

  &:hover {
    box-shadow: $shadow !important;

    .equipment-card__title {
      color: $secondary_color;
    }
  }
}

.equipment-card__title {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 1rem;
  text-transform: uppercase;
  color: $primary_color_light;
  font-weight: 500;

  &:hover {
    color: $secondary_color
  }
}

.equipment-card__image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.equipment-card__specifications__item {
  margin-bottom: .25rem;
  font-size: .85rem;
}

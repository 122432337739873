.parts {
  margin-bottom: $card_bottom_gap;
  padding: 0 $default_gap;
  background: $white;
  box-shadow: $shadow_sm;
}

.parts__item {
  border: $border_light;
  padding: $default_gap;
  transition: 0.3s all ease;

  &:hover {
    box-shadow: $shadow;
  }
}

.parts__item__image-container {
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.parts__item__image {
  margin-bottom: $default_gap;
  width: 100%;
}

.parts__item__image-link {
  display: block;
}

.parts__item__name,
.parts__item__producer-id {
  font-size: .8rem;
}

.rtl .parts__item__name{
    font-size: 1rem;
}

.parts__item__price {
  margin: $default_gap 0;
  font-weight: 500;
  font-size: .85rem;
}

@media  (min-width: 400px) {
  .parts__item__image-container {
    height: 150px;
  }
}

@include for-tablet-portrait {
  .parts__item__image-content {
    display: grid !important;
    grid-template-columns: 100px auto;
  }
  .parts__item__price {
    margin: 0;
    text-align: center;
    font-size: 1rem;
  }

  .parts__item__name,
  .parts__item__producer-id {
    font-size: .9rem;
  }

  .rtl .parts__item__name{
      font-size: 1.2rem;
  }
}

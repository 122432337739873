.footer-top {
    padding: 15px;
    text-align: center;
    background: $primary_color_light;
}

.footer-top__column {
    margin-bottom: $card_bottom_gap;
}

// About us column
.footer-top__title {
    padding: 25px 0;
    margin-bottom: 0;
    color: $muted_color;
}

.footer-top__logo {
    padding: 20px 0 15px 0;
    width: 200px;
}

.footer-top__slogan {
    color: $muted_color;
    margin-bottom: 0;
}

// Socials column
.footer-top__social {
    display: flex;
    justify-content: center;
}

.footer-top__social-item {
    margin: 0 10px;
}

.footer-top__social-icon {
    height: $social_icon_size_m;
    width: $social_icon_size_m;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.footer-top__social-label {
    padding: 0 30px;
    display: none;
    line-height: $social_icon_size;
    font-size: 14px;
    transition: $default_transition;
}

// Footer menu column
.footer-top__menu {
    nav {
        display: flex;
        flex-direction: column;

        a {
            padding-bottom: 10px;
            font-size: 1rem;
            font-weight: 400;
            color: $muted_color;
            transition: $default_transition;

            &:last-child {
                padding-bottom: 0;
            }

            &:hover,
            &.active {
                color: $muted_color_ultra_light;
            }
        }
    }
}

// Contact Us column
.footer-top__contact-item {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.footer-top__contact-icon {
    margin-bottom: $default_gap;
    color: $secondary_color_muted;
    font-size: 25px;
}

.footer-top__contact-label {
    display: flex;
    padding: 0 10px;
    justify-content: center;
    line-height: 1.2;
    align-self: center;
    font-size: .85rem;
    transition: $default_transition;
}

.footer-top__social-label,
.footer-top__contact-label {
    color: $muted_color_ultra_light;

    &:hover {
        color: darken($muted_color_ultra_light, 20%);
    }
}

.footer-top__contact-label_not-hovered {
    &:hover {
        color: $muted_color_ultra_light;
    }
}

// Footer bottom
.footer-bottom {
    padding: 15px 0;
    background: $primary_color;
}

.footer-bottom__copyright-text {
    margin: 0;
    text-align: center;
    font-size: .8rem;

    a {
        color: $white;
    }
}

@include for-phone {
    .footer-top {
        padding: 30px 0 50px;
        text-align: unset;
    }

    .footer-top__column {
        margin-bottom: 0;
    }

    .footer-top__social {
        flex-flow: column;
    }

    .footer-top__social-item {
        margin: 0 0 10px;
    }

    .footer-top__social-label {
        display: inline-block;
    }

    .footer-top__contact-item {
        display: flex;
    }

    .footer-top__contact-icon {
        margin-bottom: 0;
        font-size: 20px;
    }

    .footer-bottom__copyright-text {
        font-size: .9rem;
    }
}

@include for-tablet-portrait {
    .footer-top__social-icon {
        height: $social_icon_size;
        width: $social_icon_size;
    }
}
